import { createBrowserRouter, RouterProvider, BrowserRouter } from 'react-router-dom';

import Layout from "./layouts/Layout";
import UserLayout from "./layouts/UserLayout";

import BookingForm from "./pages/Booking/BookingForm";
import BookingList from "./pages/Booking/BookingList";
import BookingSuccess from "./pages/Booking/BookingSuccess";

import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Logout from "./pages/Auth/Logout";


function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <UserLayout />,
            children: [
                {
                    path: "",
                    element: <BookingForm />,
                },
                {
                    path: "booking",
                    element: <BookingList />,
                },
                {
                    path: "booking-success",
                    element: <BookingSuccess />,
                },
            ]
        },
        {
            path: "/auth",
            element: <Layout />,
            children: [
                {
                    path: "login",
                    element: <Login />,
                },
                {
                    path: "register",
                    element: <Register />,
                },
                {
                    path: "forgot-password",
                    element: <ForgotPassword />,
                },
                {
                    path: "logout",
                    element: <Logout />,
                },
            ]
        },
    ])

    return (
        <RouterProvider router={router} />
    )
}

export default App;

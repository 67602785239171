import React from 'react';
import { useNavigate } from "react-router-dom";
import { removeUserSession } from '../../helpers/Helper';
import useMounted from '../../hooks/useMounted';


export default function Logout(props) {
    const navigate = useNavigate();

    useMounted(() => {
        removeUserSession();
        navigate('/auth/login')
    }, [])

   
    return (<>
    </>
    );
}
import axios from "axios"

const axiosApi = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		'Content-Type': 'application/json',
		'appversion': '1.1'
	},
	responseType: 'json',
})

const handleUnauthorizedError = (error) => {
	if (error.response && error.response.status === 401) {
		window.location.href = '/auth/logout'; // Redirect to logout page
	}
	return Promise.reject(error);
};

export async function get(url, config = {}) {

	axiosApi.defaults.headers.Authorization = localStorage.getItem('token');
	return await axiosApi.get(url, { ...config })
	.then(response => response.data)
	.catch((error) => {handleUnauthorizedError(error); return error;})
}

export async function post(url, data, config = {}) {
	axiosApi.defaults.headers.Authorization = localStorage.getItem('token');
	return axiosApi
		.post(url, { ...data }, { ...config })
		.then(response => response.data)
		.catch((error) => {handleUnauthorizedError(error); return error;})
}

export async function put(url, data, config = {}) {
	axiosApi.defaults.headers.Authorization = localStorage.getItem('token');
	return axiosApi
		.put(url, { ...data }, { ...config })
		.then(response => response.data)
		.catch((error) => {handleUnauthorizedError(error); return error;})
}

export async function del(url, config = {}) {
	axiosApi.defaults.headers.Authorization = localStorage.getItem('token');
	return await axiosApi
		.delete(url, { ...config })
		.then(response => response.data)
		.catch((error) => {handleUnauthorizedError(error); return error;})
}
